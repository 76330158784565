import Sentry from 'src/lib/sentry'
import type { ReactNode } from 'react'

import { RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import { AuthProvider, useAuth } from './auth'

import { Toaster } from '@redwoodjs/web/toast'

import './index.css'
import './scaffold.css'
import './layouts.css'
import './gradients.css'

interface AppProps {
  children?: ReactNode
}

const App = ({ children }: AppProps) => (
  <Sentry.ErrorBoundary>
    <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
      <AuthProvider>
        <RedwoodApolloProvider useAuth={useAuth}>
          <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />
          {children}
        </RedwoodApolloProvider>
      </AuthProvider>
    </RedwoodProvider>
  </Sentry.ErrorBoundary>
)

export default App
