// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Set, Router, Route, PrivateSet } from '@redwoodjs/router'

import ScaffoldLayout from 'src/layouts/ScaffoldLayout'
import AppLayout from 'src/layouts/AppLayout/AppLayout'
import { useAuth } from './auth'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <PrivateSet wrap={AppLayout} unauthenticated="home">
        <Route path="/app/me" page={AppMePage} name="appMe" />
        <Route path="/app/dashboard" page={AppDashboardPage} name="appDashboard" />
        <Route path="/app/devices" page={AppDevicesPage} name="appDevices" />
        <Route path="/app/sessions" page={AppSessionsPage} name="appSessions" />
      </PrivateSet>
      <PrivateSet unauthenticated="home" roles="2ooFelr0RG1GOEtvI9u4CudaWbx">
        <Route path="/ring-zero" page={RingZeroPage} name="ringZero" />
        <Set wrap={ScaffoldLayout} title="AuditRoles" titleTo="ringZeroAuditRoles" buttonLabel="New AuditRole" buttonTo="ringZeroNewAuditRole">
          <Route path="/ring-zero/audit-roles/new" page={RingZeroAuditRoleNewAuditRolePage} name="ringZeroNewAuditRole" />
          <Route path="/ring-zero/audit-roles/{id}/edit" page={RingZeroAuditRoleEditAuditRolePage} name="ringZeroEditAuditRole" />
          <Route path="/ring-zero/audit-roles/{id}" page={RingZeroAuditRoleAuditRolePage} name="ringZeroAuditRole" />
          <Route path="/ring-zero/audit-roles" page={RingZeroAuditRoleAuditRolesPage} name="ringZeroAuditRoles" />
        </Set>
        <Set wrap={ScaffoldLayout} title="AuditLogs" titleTo="ringZeroAuditLogs" buttonLabel="New AuditLog" buttonTo="ringZeroNewAuditLog">
          <Route path="/ring-zero/audit-logs/new" page={RingZeroAuditLogNewAuditLogPage} name="ringZeroNewAuditLog" />
          <Route path="/ring-zero/audit-logs/{id}/edit" page={RingZeroAuditLogEditAuditLogPage} name="ringZeroEditAuditLog" />
          <Route path="/ring-zero/audit-logs/{id}" page={RingZeroAuditLogAuditLogPage} name="ringZeroAuditLog" />
          <Route path="/ring-zero/audit-logs" page={RingZeroAuditLogAuditLogsPage} name="ringZeroAuditLogs" />
        </Set>
        <Set wrap={ScaffoldLayout} title="InternalSystemRoles" titleTo="ringZeroInternalSystemRoles" buttonLabel="New InternalSystemRole" buttonTo="ringZeroNewInternalSystemRole">
          <Route path="/ring-zero/internal-system-roles/new" page={RingZeroInternalSystemRoleNewInternalSystemRolePage} name="ringZeroNewInternalSystemRole" />
          <Route path="/ring-zero/internal-system-roles/{id}/edit" page={RingZeroInternalSystemRoleEditInternalSystemRolePage} name="ringZeroEditInternalSystemRole" />
          <Route path="/ring-zero/internal-system-roles/{id}" page={RingZeroInternalSystemRoleInternalSystemRolePage} name="ringZeroInternalSystemRole" />
          <Route path="/ring-zero/internal-system-roles" page={RingZeroInternalSystemRoleInternalSystemRolesPage} name="ringZeroInternalSystemRoles" />
        </Set>
        <Set wrap={ScaffoldLayout} title="InternalSystemRolePrivilegeRegistries" titleTo="ringZeroInternalSystemRolePrivilegeRegistries" buttonLabel="New InternalSystemRolePrivilegeRegistry" buttonTo="ringZeroNewInternalSystemRolePrivilegeRegistry">
          <Route path="/ring-zero/internal-system-role-privilege-registries/new" page={RingZeroInternalSystemRolePrivilegeRegistryNewInternalSystemRolePrivilegeRegistryPage} name="ringZeroNewInternalSystemRolePrivilegeRegistry" />
          <Route path="/ring-zero/internal-system-role-privilege-registries/{id}/edit" page={RingZeroInternalSystemRolePrivilegeRegistryEditInternalSystemRolePrivilegeRegistryPage} name="ringZeroEditInternalSystemRolePrivilegeRegistry" />
          <Route path="/ring-zero/internal-system-role-privilege-registries/{id}" page={RingZeroInternalSystemRolePrivilegeRegistryInternalSystemRolePrivilegeRegistryPage} name="ringZeroInternalSystemRolePrivilegeRegistry" />
          <Route path="/ring-zero/internal-system-role-privilege-registries" page={RingZeroInternalSystemRolePrivilegeRegistryInternalSystemRolePrivilegeRegistriesPage} name="ringZeroInternalSystemRolePrivilegeRegistries" />
        </Set>
        <Set wrap={ScaffoldLayout} title="SystemPrivileges" titleTo="ringZeroSystemPrivileges" buttonLabel="New SystemPrivilege" buttonTo="ringZeroNewSystemPrivilege">
          <Route path="/ring-zero/system-privileges/new" page={RingZeroSystemPrivilegeNewSystemPrivilegePage} name="ringZeroNewSystemPrivilege" />
          <Route path="/ring-zero/system-privileges/{id}/edit" page={RingZeroSystemPrivilegeEditSystemPrivilegePage} name="ringZeroEditSystemPrivilege" />
          <Route path="/ring-zero/system-privileges/{id}" page={RingZeroSystemPrivilegeSystemPrivilegePage} name="ringZeroSystemPrivilege" />
          <Route path="/ring-zero/system-privileges" page={RingZeroSystemPrivilegeSystemPrivilegesPage} name="ringZeroSystemPrivileges" />
        </Set>
        <Set wrap={ScaffoldLayout} title="Sessions" titleTo="ringZeroSessions" buttonLabel="New Session" buttonTo="ringZeroNewSession">
          <Route path="/ring-zero/sessions/new" page={RingZeroSessionNewSessionPage} name="ringZeroNewSession" />
          <Route path="/ring-zero/sessions/{id}/edit" page={RingZeroSessionEditSessionPage} name="ringZeroEditSession" />
          <Route path="/ring-zero/sessions/{id}" page={RingZeroSessionSessionPage} name="ringZeroSession" />
          <Route path="/ring-zero/sessions" page={RingZeroSessionSessionsPage} name="ringZeroSessions" />
        </Set>
        <Set wrap={ScaffoldLayout} title="Taps" titleTo="ringZeroTaps" buttonLabel="New Tap" buttonTo="ringZeroNewTap">
          <Route path="/ring-zero/taps/new" page={RingZeroTapNewTapPage} name="ringZeroNewTap" />
          <Route path="/ring-zero/taps/{id}/edit" page={RingZeroTapEditTapPage} name="ringZeroEditTap" />
          <Route path="/ring-zero/taps/{id}" page={RingZeroTapTapPage} name="ringZeroTap" />
          <Route path="/ring-zero/taps" page={RingZeroTapTapsPage} name="ringZeroTaps" />
        </Set>
        <Set wrap={ScaffoldLayout} title="Frames" titleTo="ringZeroFrames" buttonLabel="New Frame" buttonTo="ringZeroNewFrame">
          <Route path="/ring-zero/frames/new" page={RingZeroFrameNewFramePage} name="ringZeroNewFrame" />
          <Route path="/ring-zero/frames/{id}/edit" page={RingZeroFrameEditFramePage} name="ringZeroEditFrame" />
          <Route path="/ring-zero/frames/{id}" page={RingZeroFrameFramePage} name="ringZeroFrame" />
          <Route path="/ring-zero/frames" page={RingZeroFrameFramesPage} name="ringZeroFrames" />
        </Set>
        <Set wrap={ScaffoldLayout} title="OrganizationBillingPlanRegistries" titleTo="ringZeroOrganizationBillingPlanRegistries" buttonLabel="New OrganizationBillingPlanRegistry" buttonTo="ringZeroNewOrganizationBillingPlanRegistry">
          <Route path="/ring-zero/organization-billing-plan-registries/new" page={RingZeroOrganizationBillingPlanRegistryNewOrganizationBillingPlanRegistryPage} name="ringZeroNewOrganizationBillingPlanRegistry" />
          <Route path="/ring-zero/organization-billing-plan-registries/{id}/edit" page={RingZeroOrganizationBillingPlanRegistryEditOrganizationBillingPlanRegistryPage} name="ringZeroEditOrganizationBillingPlanRegistry" />
          <Route path="/ring-zero/organization-billing-plan-registries/{id}" page={RingZeroOrganizationBillingPlanRegistryOrganizationBillingPlanRegistryPage} name="ringZeroOrganizationBillingPlanRegistry" />
          <Route path="/ring-zero/organization-billing-plan-registries" page={RingZeroOrganizationBillingPlanRegistryOrganizationBillingPlanRegistriesPage} name="ringZeroOrganizationBillingPlanRegistries" />
        </Set>
        <Set wrap={ScaffoldLayout} title="BillingPlanEntitlementRegistries" titleTo="ringZeroBillingPlanEntitlementRegistries" buttonLabel="New BillingPlanEntitlementRegistry" buttonTo="ringZeroNewBillingPlanEntitlementRegistry">
          <Route path="/ring-zero/billing-plan-entitlement-registries/new" page={RingZeroBillingPlanEntitlementRegistryNewBillingPlanEntitlementRegistryPage} name="ringZeroNewBillingPlanEntitlementRegistry" />
          <Route path="/ring-zero/billing-plan-entitlement-registries/{id}/edit" page={RingZeroBillingPlanEntitlementRegistryEditBillingPlanEntitlementRegistryPage} name="ringZeroEditBillingPlanEntitlementRegistry" />
          <Route path="/ring-zero/billing-plan-entitlement-registries/{id}" page={RingZeroBillingPlanEntitlementRegistryBillingPlanEntitlementRegistryPage} name="ringZeroBillingPlanEntitlementRegistry" />
          <Route path="/ring-zero/billing-plan-entitlement-registries" page={RingZeroBillingPlanEntitlementRegistryBillingPlanEntitlementRegistriesPage} name="ringZeroBillingPlanEntitlementRegistries" />
        </Set>
        <Set wrap={ScaffoldLayout} title="BillingPlans" titleTo="ringZeroBillingPlans" buttonLabel="New BillingPlan" buttonTo="ringZeroNewBillingPlan">
          <Route path="/ring-zero/billing-plans/new" page={RingZeroBillingPlanNewBillingPlanPage} name="ringZeroNewBillingPlan" />
          <Route path="/ring-zero/billing-plans/{id}/edit" page={RingZeroBillingPlanEditBillingPlanPage} name="ringZeroEditBillingPlan" />
          <Route path="/ring-zero/billing-plans/{id}" page={RingZeroBillingPlanBillingPlanPage} name="ringZeroBillingPlan" />
          <Route path="/ring-zero/billing-plans" page={RingZeroBillingPlanBillingPlansPage} name="ringZeroBillingPlans" />
        </Set>
        <Set wrap={ScaffoldLayout} title="Entitlements" titleTo="ringZeroEntitlements" buttonLabel="New Entitlement" buttonTo="ringZeroNewEntitlement">
          <Route path="/ring-zero/entitlements/new" page={RingZeroEntitlementNewEntitlementPage} name="ringZeroNewEntitlement" />
          <Route path="/ring-zero/entitlements/{id}/edit" page={RingZeroEntitlementEditEntitlementPage} name="ringZeroEditEntitlement" />
          <Route path="/ring-zero/entitlements/{id}" page={RingZeroEntitlementEntitlementPage} name="ringZeroEntitlement" />
          <Route path="/ring-zero/entitlements" page={RingZeroEntitlementEntitlementsPage} name="ringZeroEntitlements" />
        </Set>
        <Set wrap={ScaffoldLayout} title="UserOrganizationRoleRegistries" titleTo="ringZeroUserOrganizationRoleRegistries" buttonLabel="New UserOrganizationRoleRegistry" buttonTo="ringZeroNewUserOrganizationRoleRegistry">
          <Route path="/ring-zero/user-organization-role-registries/new" page={RingZeroUserOrganizationRoleRegistryNewUserOrganizationRoleRegistryPage} name="ringZeroNewUserOrganizationRoleRegistry" />
          <Route path="/ring-zero/user-organization-role-registries/{id}/edit" page={RingZeroUserOrganizationRoleRegistryEditUserOrganizationRoleRegistryPage} name="ringZeroEditUserOrganizationRoleRegistry" />
          <Route path="/ring-zero/user-organization-role-registries/{id}" page={RingZeroUserOrganizationRoleRegistryUserOrganizationRoleRegistryPage} name="ringZeroUserOrganizationRoleRegistry" />
          <Route path="/ring-zero/user-organization-role-registries" page={RingZeroUserOrganizationRoleRegistryUserOrganizationRoleRegistriesPage} name="ringZeroUserOrganizationRoleRegistries" />
        </Set>
        <Set wrap={ScaffoldLayout} title="UserOrganizationRegistries" titleTo="ringZeroUserOrganizationRegistries" buttonLabel="New UserOrganizationRegistry" buttonTo="ringZeroNewUserOrganizationRegistry">
          <Route path="/ring-zero/user-organization-registries/new" page={RingZeroUserOrganizationRegistryNewUserOrganizationRegistryPage} name="ringZeroNewUserOrganizationRegistry" />
          <Route path="/ring-zero/user-organization-registries/{id}/edit" page={RingZeroUserOrganizationRegistryEditUserOrganizationRegistryPage} name="ringZeroEditUserOrganizationRegistry" />
          <Route path="/ring-zero/user-organization-registries/{id}" page={RingZeroUserOrganizationRegistryUserOrganizationRegistryPage} name="ringZeroUserOrganizationRegistry" />
          <Route path="/ring-zero/user-organization-registries" page={RingZeroUserOrganizationRegistryUserOrganizationRegistriesPage} name="ringZeroUserOrganizationRegistries" />
        </Set>
        <Set wrap={ScaffoldLayout} title="UserHandlerRoleRegistries" titleTo="ringZeroUserHandlerRoleRegistries" buttonLabel="New UserHandlerRoleRegistry" buttonTo="ringZeroNewUserHandlerRoleRegistry">
          <Route path="/ring-zero/user-handler-role-registries/new" page={RingZeroUserHandlerRoleRegistryNewUserHandlerRoleRegistryPage} name="ringZeroNewUserHandlerRoleRegistry" />
          <Route path="/ring-zero/user-handler-role-registries/{id}/edit" page={RingZeroUserHandlerRoleRegistryEditUserHandlerRoleRegistryPage} name="ringZeroEditUserHandlerRoleRegistry" />
          <Route path="/ring-zero/user-handler-role-registries/{id}" page={RingZeroUserHandlerRoleRegistryUserHandlerRoleRegistryPage} name="ringZeroUserHandlerRoleRegistry" />
          <Route path="/ring-zero/user-handler-role-registries" page={RingZeroUserHandlerRoleRegistryUserHandlerRoleRegistriesPage} name="ringZeroUserHandlerRoleRegistries" />
        </Set>
        <Set wrap={ScaffoldLayout} title="UserHandlerRegistries" titleTo="ringZeroUserHandlerRegistries" buttonLabel="New UserHandlerRegistry" buttonTo="ringZeroNewUserHandlerRegistry">
          <Route path="/ring-zero/user-handler-registries/new" page={RingZeroUserHandlerRegistryNewUserHandlerRegistryPage} name="ringZeroNewUserHandlerRegistry" />
          <Route path="/ring-zero/user-handler-registries/{id}/edit" page={RingZeroUserHandlerRegistryEditUserHandlerRegistryPage} name="ringZeroEditUserHandlerRegistry" />
          <Route path="/ring-zero/user-handler-registries/{id}" page={RingZeroUserHandlerRegistryUserHandlerRegistryPage} name="ringZeroUserHandlerRegistry" />
          <Route path="/ring-zero/user-handler-registries" page={RingZeroUserHandlerRegistryUserHandlerRegistriesPage} name="ringZeroUserHandlerRegistries" />
        </Set>
        <Set wrap={ScaffoldLayout} title="UserInternalRoleRegistries" titleTo="ringZeroUserInternalRoleRegistries" buttonLabel="New UserInternalRoleRegistry" buttonTo="ringZeroNewUserInternalRoleRegistry">
          <Route path="/ring-zero/user-internal-role-registries/new" page={RingZeroUserInternalRoleRegistryNewUserInternalRoleRegistryPage} name="ringZeroNewUserInternalRoleRegistry" />
          <Route path="/ring-zero/user-internal-role-registries/{id}/edit" page={RingZeroUserInternalRoleRegistryEditUserInternalRoleRegistryPage} name="ringZeroEditUserInternalRoleRegistry" />
          <Route path="/ring-zero/user-internal-role-registries/{id}" page={RingZeroUserInternalRoleRegistryUserInternalRoleRegistryPage} name="ringZeroUserInternalRoleRegistry" />
          <Route path="/ring-zero/user-internal-role-registries" page={RingZeroUserInternalRoleRegistryUserInternalRoleRegistriesPage} name="ringZeroUserInternalRoleRegistries" />
        </Set>
        <Set wrap={ScaffoldLayout} title="UserInternalRegistries" titleTo="ringZeroUserInternalRegistries" buttonLabel="New UserInternalRegistry" buttonTo="ringZeroNewUserInternalRegistry">
          <Route path="/ring-zero/user-internal-registries/new" page={RingZeroUserInternalRegistryNewUserInternalRegistryPage} name="ringZeroNewUserInternalRegistry" />
          <Route path="/ring-zero/user-internal-registries/{id}/edit" page={RingZeroUserInternalRegistryEditUserInternalRegistryPage} name="ringZeroEditUserInternalRegistry" />
          <Route path="/ring-zero/user-internal-registries/{id}" page={RingZeroUserInternalRegistryUserInternalRegistryPage} name="ringZeroUserInternalRegistry" />
          <Route path="/ring-zero/user-internal-registries" page={RingZeroUserInternalRegistryUserInternalRegistriesPage} name="ringZeroUserInternalRegistries" />
        </Set>
        <Set wrap={ScaffoldLayout} title="Users" titleTo="ringZeroUsers" buttonLabel="New User" buttonTo="ringZeroNewUser">
          <Route path="/ring-zero/users/new" page={RingZeroUserNewUserPage} name="ringZeroNewUser" />
          <Route path="/ring-zero/users/{id}/edit" page={RingZeroUserEditUserPage} name="ringZeroEditUser" />
          <Route path="/ring-zero/users/{id}" page={RingZeroUserUserPage} name="ringZeroUser" />
          <Route path="/ring-zero/users" page={RingZeroUserUsersPage} name="ringZeroUsers" />
        </Set>
        <Set wrap={ScaffoldLayout} title="OrganizationSystemRolePrivilegeRegistries" titleTo="ringZeroOrganizationSystemRolePrivilegeRegistries" buttonLabel="New OrganizationSystemRolePrivilegeRegistry" buttonTo="ringZeroNewOrganizationSystemRolePrivilegeRegistry">
          <Route path="/ring-zero/organization-system-role-privilege-registries/new" page={RingZeroOrganizationSystemRolePrivilegeRegistryNewOrganizationSystemRolePrivilegeRegistryPage} name="ringZeroNewOrganizationSystemRolePrivilegeRegistry" />
          <Route path="/ring-zero/organization-system-role-privilege-registries/{id}/edit" page={RingZeroOrganizationSystemRolePrivilegeRegistryEditOrganizationSystemRolePrivilegeRegistryPage} name="ringZeroEditOrganizationSystemRolePrivilegeRegistry" />
          <Route path="/ring-zero/organization-system-role-privilege-registries/{id}" page={RingZeroOrganizationSystemRolePrivilegeRegistryOrganizationSystemRolePrivilegeRegistryPage} name="ringZeroOrganizationSystemRolePrivilegeRegistry" />
          <Route path="/ring-zero/organization-system-role-privilege-registries" page={RingZeroOrganizationSystemRolePrivilegeRegistryOrganizationSystemRolePrivilegeRegistriesPage} name="ringZeroOrganizationSystemRolePrivilegeRegistries" />
        </Set>
        <Set wrap={ScaffoldLayout} title="HandlerSystemRolePrivilegeRegistries" titleTo="ringZeroHandlerSystemRolePrivilegeRegistries" buttonLabel="New HandlerSystemRolePrivilegeRegistry" buttonTo="ringZeroNewHandlerSystemRolePrivilegeRegistry">
          <Route path="/ring-zero/handler-system-role-privilege-registries/new" page={RingZeroHandlerSystemRolePrivilegeRegistryNewHandlerSystemRolePrivilegeRegistryPage} name="ringZeroNewHandlerSystemRolePrivilegeRegistry" />
          <Route path="/ring-zero/handler-system-role-privilege-registries/{id}/edit" page={RingZeroHandlerSystemRolePrivilegeRegistryEditHandlerSystemRolePrivilegeRegistryPage} name="ringZeroEditHandlerSystemRolePrivilegeRegistry" />
          <Route path="/ring-zero/handler-system-role-privilege-registries/{id}" page={RingZeroHandlerSystemRolePrivilegeRegistryHandlerSystemRolePrivilegeRegistryPage} name="ringZeroHandlerSystemRolePrivilegeRegistry" />
          <Route path="/ring-zero/handler-system-role-privilege-registries" page={RingZeroHandlerSystemRolePrivilegeRegistryHandlerSystemRolePrivilegeRegistriesPage} name="ringZeroHandlerSystemRolePrivilegeRegistries" />
        </Set>
        <Set wrap={ScaffoldLayout} title="HandlerSystemRoles" titleTo="ringZeroHandlerSystemRoles" buttonLabel="New HandlerSystemRole" buttonTo="ringZeroNewHandlerSystemRole">
          <Route path="/ring-zero/handler-system-roles/new" page={RingZeroHandlerSystemRoleNewHandlerSystemRolePage} name="ringZeroNewHandlerSystemRole" />
          <Route path="/ring-zero/handler-system-roles/{id}/edit" page={RingZeroHandlerSystemRoleEditHandlerSystemRolePage} name="ringZeroEditHandlerSystemRole" />
          <Route path="/ring-zero/handler-system-roles/{id}" page={RingZeroHandlerSystemRoleHandlerSystemRolePage} name="ringZeroHandlerSystemRole" />
          <Route path="/ring-zero/handler-system-roles" page={RingZeroHandlerSystemRoleHandlerSystemRolesPage} name="ringZeroHandlerSystemRoles" />
        </Set>
        <Set wrap={ScaffoldLayout} title="OrganizationSystemRoles" titleTo="ringZeroOrganizationSystemRoles" buttonLabel="New OrganizationSystemRole" buttonTo="ringZeroNewOrganizationSystemRole">
          <Route path="/ring-zero/organization-system-roles/new" page={RingZeroOrganizationSystemRoleNewOrganizationSystemRolePage} name="ringZeroNewOrganizationSystemRole" />
          <Route path="/ring-zero/organization-system-roles/{id}/edit" page={RingZeroOrganizationSystemRoleEditOrganizationSystemRolePage} name="ringZeroEditOrganizationSystemRole" />
          <Route path="/ring-zero/organization-system-roles/{id}" page={RingZeroOrganizationSystemRoleOrganizationSystemRolePage} name="ringZeroOrganizationSystemRole" />
          <Route path="/ring-zero/organization-system-roles" page={RingZeroOrganizationSystemRoleOrganizationSystemRolesPage} name="ringZeroOrganizationSystemRoles" />
        </Set>
        <Set wrap={ScaffoldLayout} title="HandlerRegistries" titleTo="ringZeroHandlerRegistries" buttonLabel="New HandlerRegistry" buttonTo="ringZeroNewHandlerRegistry">
          <Route path="/ring-zero/handler-registries/new" page={RingZeroHandlerRegistryNewHandlerRegistryPage} name="ringZeroNewHandlerRegistry" />
          <Route path="/ring-zero/handler-registries/{id}/edit" page={RingZeroHandlerRegistryEditHandlerRegistryPage} name="ringZeroEditHandlerRegistry" />
          <Route path="/ring-zero/handler-registries/{id}" page={RingZeroHandlerRegistryHandlerRegistryPage} name="ringZeroHandlerRegistry" />
          <Route path="/ring-zero/handler-registries" page={RingZeroHandlerRegistryHandlerRegistriesPage} name="ringZeroHandlerRegistries" />
        </Set>
        <Set wrap={ScaffoldLayout} title="Handlers" titleTo="ringZeroHandlers" buttonLabel="New Handler" buttonTo="ringZeroNewHandler">
          <Route path="/ring-zero/handlers/new" page={RingZeroHandlerNewHandlerPage} name="ringZeroNewHandler" />
          <Route path="/ring-zero/handlers/{id}/edit" page={RingZeroHandlerEditHandlerPage} name="ringZeroEditHandler" />
          <Route path="/ring-zero/handlers/{id}" page={RingZeroHandlerHandlerPage} name="ringZeroHandler" />
          <Route path="/ring-zero/handlers" page={RingZeroHandlerHandlersPage} name="ringZeroHandlers" />
        </Set>
        <Set wrap={ScaffoldLayout} title="DeviceRegistries" titleTo="ringZeroDeviceRegistries" buttonLabel="New DeviceRegistry" buttonTo="ringZeroNewDeviceRegistry">
          <Route path="/ring-zero/device-registries/new" page={RingZeroDeviceRegistryNewDeviceRegistryPage} name="ringZeroNewDeviceRegistry" />
          <Route path="/ring-zero/device-registries/{id}/edit" page={RingZeroDeviceRegistryEditDeviceRegistryPage} name="ringZeroEditDeviceRegistry" />
          <Route path="/ring-zero/device-registries/{id}" page={RingZeroDeviceRegistryDeviceRegistryPage} name="ringZeroDeviceRegistry" />
          <Route path="/ring-zero/device-registries" page={RingZeroDeviceRegistryDeviceRegistriesPage} name="ringZeroDeviceRegistries" />
        </Set>
        <Set wrap={ScaffoldLayout} title="Organizations" titleTo="ringZeroOrganizations" buttonLabel="New Organization" buttonTo="ringZeroNewOrganization">
          <Route path="/ring-zero/organizations/new" page={RingZeroOrganizationNewOrganizationPage} name="ringZeroNewOrganization" />
          <Route path="/ring-zero/organizations/{id}/edit" page={RingZeroOrganizationEditOrganizationPage} name="ringZeroEditOrganization" />
          <Route path="/ring-zero/organizations/{id}" page={RingZeroOrganizationOrganizationPage} name="ringZeroOrganization" />
          <Route path="/ring-zero/organizations" page={RingZeroOrganizationOrganizationsPage} name="ringZeroOrganizations" />
        </Set>
        <Set wrap={ScaffoldLayout} title="Devices" titleTo="ringZeroDevices" buttonLabel="New Device" buttonTo="ringZeroNewDevice">
          <Route path="/ring-zero/devices/new" page={RingZeroDeviceNewDevicePage} name="ringZeroNewDevice" />
          <Route path="/ring-zero/devices/{id}/edit" page={RingZeroDeviceEditDevicePage} name="ringZeroEditDevice" />
          <Route path="/ring-zero/devices/{id}" page={RingZeroDeviceDevicePage} name="ringZeroDevice" />
          <Route path="/ring-zero/devices" page={RingZeroDeviceDevicesPage} name="ringZeroDevices" />
        </Set>
        <Set wrap={ScaffoldLayout} title="DeviceManufacturers" titleTo="ringZeroDeviceManufacturers" buttonLabel="New DeviceManufacturer" buttonTo="ringZeroNewDeviceManufacturer">
          <Route path="/ring-zero/device-manufacturers/new" page={RingZeroDeviceManufacturerNewDeviceManufacturerPage} name="ringZeroNewDeviceManufacturer" />
          <Route path="/ring-zero/device-manufacturers/{id}/edit" page={RingZeroDeviceManufacturerEditDeviceManufacturerPage} name="ringZeroEditDeviceManufacturer" />
          <Route path="/ring-zero/device-manufacturers/{id}" page={RingZeroDeviceManufacturerDeviceManufacturerPage} name="ringZeroDeviceManufacturer" />
          <Route path="/ring-zero/device-manufacturers" page={RingZeroDeviceManufacturerDeviceManufacturersPage} name="ringZeroDeviceManufacturers" />
        </Set>
      </PrivateSet>
      <Route path="/login" page={LoginPage} name="login" />
      <Route path="/signup" page={SignupPage} name="signup" />
      <Route path="/forgot-password" page={ForgotPasswordPage} name="forgotPassword" />
      <Route path="/reset-password" page={ResetPasswordPage} name="resetPassword" />
      <Route path="/" page={HomePage} name="home" />
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
