import { useState, useEffect } from 'react'
import { Link, NavLink, routes, useLocation } from '@redwoodjs/router'
import { useAuth } from 'src/auth'

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  CommandLineIcon,
  CpuChipIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  PlayCircleIcon,
  PlayIcon,
  UserCircleIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

type AppLayoutProps = {
  children?: React.ReactNode
}

const AppLayout = ({ children }: AppLayoutProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [userMenuOpen, setUserMenuOpen] = useState(false)
  const { currentUser, logOut } = useAuth()
  const { pathname } = useLocation()

  useEffect(() => {
    setUserMenuOpen(false)
  }, [pathname])

  const userNavigation = [
    { name: 'Your profile', route: routes.appMe() },
    { name: 'Sign out', onClick: logOut },
  ]

  const navigation = [
    {
      name: 'Dashboard',
      route: routes.appDashboard(),
      icon: HomeIcon,
    },
    {
      name: 'Sessions',
      route: routes.appSessions(),
      icon: PlayIcon,
    },
    {
      name: 'Devices',
      route: routes.appDevices(),
      icon: CpuChipIcon,
    },
    //{ name: 'Team', href: '#', icon: UsersIcon, current: false },
    //{ name: 'Projects', href: '#', icon: FolderIcon, current: false },
    //{ name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
    //{ name: 'Documents', href: '#', icon: DocumentDuplicateIcon, current: false },
    //{ name: 'Reports', href: '#', icon: ChartPieIcon, current: false },
  ]

  const { hasRole } = useAuth()
  const ringZero = hasRole('2ooFelr0RG1GOEtvI9u4CudaWbx')

  return (
    <>
      <div>
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className="relative z-50 lg:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      aria-hidden="true"
                      className="size-6 text-white"
                    />
                  </button>
                </div>
              </TransitionChild>

              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                <div className="flex h-16 shrink-0 items-center">
                  <img
                    alt="Sessions Recorder"
                    src="/logo.svg"
                    className="h-8 w-auto"
                  />
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="-mx-2 flex-1 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.route}
                          className="group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold text-gray-400 hover:bg-gray-800 hover:text-white"
                          activeClassName="group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold bg-gray-800 text-white"
                        >
                          <item.icon
                            aria-hidden="true"
                            className="size-6 shrink-0"
                          />
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:grid lg:w-20 lg:grid-rows-[auto_1fr_auto] lg:overflow-y-auto lg:bg-slate-800 lg:pb-4">
          <div className="flex h-16 shrink-0 items-center justify-center">
            <img
              alt="Sessions Recorder"
              src="/logolight.svg"
              className="h-8 w-auto"
            />
          </div>
          <nav className="mt-4">
            <ul role="list" className="flex flex-col items-center space-y-1">
              {navigation.map((item) => (
                <li key={item.name}>
                  <NavLink
                    to={item.route}
                    className="group flex gap-x-3 rounded-md p-3 text-sm/6 font-semibold text-gray-400 hover:bg-slate-900 hover:text-white"
                    activeClassName="group flex gap-x-3 rounded-md p-3 text-sm/6 font-semibold bg-slate-900 text-white hover:bg-slate-900 hover:text-white"
                  >
                    <item.icon aria-hidden="true" className="size-6 shrink-0" />
                    <span className="sr-only">{item.name}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
          {ringZero ? (
            <nav className="">
              <ul role="list" className="flex flex-col items-center space-y-1">
                <li>
                  <Link
                    to={routes.ringZero()}
                    className="group flex gap-x-3 rounded-md p-3 text-sm/6 font-semibold text-brand-300 hover:bg-slate-900 hover:text-originalPurple"
                  >
                    <CommandLineIcon
                      aria-hidden="true"
                      className="size-6 shrink-0"
                    />
                    <span className="sr-only">Ring Zero</span>
                  </Link>
                </li>
              </ul>
            </nav>
          ) : (
            <></>
          )}
        </div>

        <div className="dash-grid h-dvh lg:pl-20">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              onClick={() => setSidebarOpen(true)}
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon aria-hidden="true" className="size-6" />
            </button>

            {/* Separator */}
            <div
              aria-hidden="true"
              className="h-6 w-px bg-gray-900/10 lg:hidden"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="relative flex flex-1">{}</div>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                {/*
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon aria-hidden="true" className="size-6" />
                </button>
                */}

                {/* Separator */}
                <div
                  aria-hidden="true"
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <MenuButton
                    className="-m-1.5 flex items-center p-1.5"
                    onClick={() => setUserMenuOpen(true)}
                  >
                    <span className="sr-only">Open user menu</span>
                    <UserCircleIcon
                      aria-hidden="true"
                      className="size-6 shrink-0"
                    />
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        aria-hidden="true"
                        className="ml-4 text-sm/6 font-semibold text-gray-900"
                      >
                        {currentUser?.displayName}
                      </span>
                      <ChevronDownIcon
                        aria-hidden="true"
                        className="ml-2 size-5 text-gray-400"
                      />
                    </span>
                  </MenuButton>
                  <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    {userMenuOpen &&
                      userNavigation.map((item) => (
                        <MenuItem key={item.name}>
                          {item.onClick ? (
                            <a
                              href="#"
                              className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                              onClick={item.onClick}
                            >
                              {item.name}
                            </a>
                          ) : (
                            <Link
                              to={item.route}
                              className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                            >
                              {item.name}
                            </Link>
                          )}
                        </MenuItem>
                      ))}
                  </MenuItems>
                </Menu>
              </div>
            </div>
          </div>

          <main className="h-full overflow-y-auto p-4">{children}</main>

          <footer className="flex h-16 shrink-0 items-center justify-between gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <p>Sessions Recorder</p>
            <p>© {new Date().getFullYear()}</p>
          </footer>
        </div>
      </div>
    </>
  )
}

export default AppLayout
